import React from 'react';
import './App.css';
import cardsImage from './card.webp' // Добавьте изображение карт в папку src и измените путь

function App() {
  return (
    <div className="App">
      <header className="banner">
        <a href='https://t.me/hamster_komBat_bot/start?startapp=kentId265815885'>
          <div className="hamster-block">
            <div className="hamster-image"></div>
            <h2 className="hamster-text">Тапай в хомяка с нами</h2>
          </div>
        </a>
        <h1>Оформить карту тинькофф</h1>
        <p>Зарабатывайте кэшбэк рублями, а не бонусами. Оплачивайте счета и переводите деньги без комиссии. Закажите дебетовую карту с платежной системой «Мир»</p>
        <a href="https://www.tinkoff.ru/sl/3xSMbynKcHL" className="cta-button">Оформить карту</a>
      </header>
      <div className="info-block">
        <img src={cardsImage} alt="Карты Тинькофф Банк" />
      </div>
      <div className="features">
        <div className="feature">
          <h2>Кэшбэк рублями до 30%</h2>
          <p>За покупки по спецпредложениям партнеров банка.</p>
        </div>
        <div className="feature">
          <h2>0 ₽ за обслуживание</h2>
          <p>Если хранить от 50 000 ₽ на карте, вкладах, накопительных счетах и в инвестициях. В других случаях 99 ₽ в месяц</p>
        </div>
        <div class="feature feature-black">
          <h2 class="h2-black">Платежи и переводы без комиссии</h2>
          <p class="lastP">Переводы на карты других банков, оплата ЖКУ, штрафов ГИБДД, связи — без комиссии с картой Т‑Банка</p>
        </div>
      </div>
    </div>
  );
}

export default App;

<div class="feature feature-black">
  <h2 class="h2-black">Платежи и переводы без комиссии</h2>
  <p class="lastP">Переводы на карты других банков, оплата ЖКУ, штрафов ГИБДД, связи — без комиссии с картой Т‑Банка</p>
</div>
